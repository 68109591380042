import {DOM} from "lib/commons/xml/dom";
import {REG} from "lib/commons/registry";
import {Desk} from "lib/commons/desk";
import {IError} from "lib/commons/errorLog";

/** URL d'un server intégrant sa gestion de l'authentification. */
export interface IEndPoint {

	/**
	 * URL normalisée du server constituée des : protocole, domaine, port et path du endPoint.
	 * Généralement, l'URL se termine par un '/', sauf si le endPoint représente un noeud terminal.
	 */
	url: string

	/**
	 * Fetch intégrant un parsing éventuel du résultat et garantissant que la promesse n'est jamais rejetée.
	 * En cas d'erreur réseau qui a provoqué un rejet de la promesse interne, une IResponse est retournée avec
	 * un statut 599 et IResponse.error renseigné.
	 *
	 * @param path Path qui devrait être relatif à l'URL de ce IEndPoint (ie ne commencant pas par '/').
	 *    L'appelant peut néanmoins avoir déjà résolu le path en une url complète ou absolue en amont.
	 *    Si null ou "", fetch sur l'URL de ce endPoint.
	 */
	fetch(path?: string, format?: ERespFormat /*='none'*/, init?: RequestInit): Promise<IResponse>

	/**
	 * Fetch un json ou null (si 204 ou 404).
	 * NE PAS OUBLIER de traiter le rejet de la promesse (catch).
	 * En cas de response 204 ou 404, null est retourné.
	 * En cas de response en erreur (status hors plage [200-299] et != 404, une RespError est retournée en exception.
	 * En cas d'echec à une autre étape, (parsing...) l'erreur originelle est retournée en exception.
	 *
	 * @param path Path qui devrait être relatif à l'URL de ce IEndPoint (ie ne commencant pas par '/').
	 *    L'appelant peut néanmoins avoir déjà résolu le path en une url complète ou absolue en amont.
	 *    Si null ou "", fetch sur l'URL de ce endPoint.
	 */
	fetchJson<R>(path?: string, init?: RequestInit): Promise<R | null>

	/**
	 * Fetch une string ou null (si 204 ou 404).
	 * NE PAS OUBLIER de traiter le rejet de la promesse (catch).
	 * En cas de response 204 ou 404, null est retourné.
	 * En cas de response en erreur (status hors plage [200-299] et != 404, une RespError est retournée en exception.
	 * En cas d'echec à une autre étape, (parsing...) l'erreur originelle est retournée en exception.
	 *
	 * @param path Path qui devrait être relatif à l'URL de ce IEndPoint (ie ne commencant pas par '/').
	 *    L'appelant peut néanmoins avoir déjà résolu le path en une url complète ou absolue en amont.
	 *    Si null ou "", fetch sur l'URL de ce endPoint.
	 */
	fetchText(path?: string, init?: RequestInit): Promise<string | null>

	/**
	 * Fetch une string ou null (si 204 ou 404).
	 * NE PAS OUBLIER de traiter le rejet de la promesse (catch).
	 * En cas de response 204 ou 404, null est retourné.
	 * En cas de response en erreur (status hors plage [200-299] et != 404, une RespError est retournée en exception.
	 * En cas d'echec à une autre étape, (parsing...) l'erreur originelle est retournée en exception.
	 *
	 * @param path Path qui devrait être relatif à l'URL de ce IEndPoint (ie ne commencant pas par '/').
	 *    L'appelant peut néanmoins avoir déjà résolu le path en une url complète ou absolue en amont.
	 *    Si null ou "", fetch sur l'URL de ce endPoint.
	 */
	fetchDom(path?: string, init?: RequestInit): Promise<(Document & IEndPointHolder) | null>

	/**
	 * Fetch un Blob ou null (si 404).
	 * NE PAS OUBLIER de traiter le rejet de la promesse (catch).
	 * En cas de response 404, null est retourné.
	 * En cas de response en erreur (status hors plage [200-299] et != 404, une RespError est retournée en exception.
	 * En cas d'echec à une autre étape, (parsing...) l'erreur originelle est retournée en exception.
	 *
	 * @param path Path qui devrait être relatif à l'URL de ce IEndPoint (ie ne commencant pas par '/').
	 *    L'appelant peut néanmoins avoir déjà résolu le path en une url complète ou absolue en amont.
	 *    Si null ou "", fetch sur l'URL de ce endPoint.
	 */
	fetchBlob(path?: string, init?: RequestInit): Promise<Blob | null>

	/**
	 * Fetch une string ou null (si 404).
	 * NE PAS OUBLIER de traiter le rejet de la promesse (catch).
	 * En cas de response 404, null est retourné.
	 * En cas de response en erreur (status hors plage [200-299] et != 404, une RespError est retournée en exception.
	 * En cas d'echec à une autre étape, (parsing...) l'erreur originelle est retournée en exception.
	 *
	 * @param path Path qui devrait être relatif à l'URL de ce IEndPoint (ie ne commencant pas par '/').
	 *    L'appelant peut néanmoins avoir déjà résolu le path en une url complète ou absolue en amont.
	 *    Si null ou "", fetch sur l'URL de ce endPoint.
	 */
	fetchVoid(path?: string, init?: RequestInit): Promise<void | null>

	/** Importe un module JS dynamiquement. */
	importJs(path?: string): Promise<any>

	/**
	 * Retourne un nouveau IEndPont avec la même politique d'authentification et l'URL passée en paramètre
	 * @param path url relative, absolue ou complète.
	 */
	resolve(path: string): IEndPoint

	// /**
	//  * Résoud une url pour un usage sans possibilité de configurer la requête.
	//  * L'ajout de params en query string pourrait remplacer l'absence de headers par exemple.
	// XXX mais avec credentials ou sans ? Si sans credentials, pourrait exiger une pré-requete server (token) et donc retour en Promise
	//  */
	// resolvePureString(path: string, withoutCredentials: boolean): string

	/** Doit retourner this.url (simplification d'écriture). */
	toString(): string
}

/**
 * Raccroche un IEndPoint à un objet métier ou un Document.
 * Permet par exemple de résoudre des liens relatifs:
 * doc.baseEndPoint.fetch(elt.getAttribute('src'));
 */
export interface IEndPointHolder {
	/** endPoint source de cet objet. */
	baseEndPoint?: IEndPoint
}

export function isEndPointHolder(o: any): o is IEndPointHolder {return o && o.baseEndPoint != null}


/** Réécriture ou resolver de préfixes de paths. */
export interface IPathResolver {

	/** Réécrit une string considérée comme une et une seule url. */
	resolvePath(path: string): string

	/** Réécrit la ou les url incluses dans ce texte. */
	resolveInText(text: string /*au besoin: , format?:'css'|'js' */): string
}

/**
 * Hypertext Transfer Protocol (HTTP) response status codes.
 *
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes}
 * @see {@link https://gist.githubusercontent.com/RWOverdijk/6cef816cfdf5722228e01cc05fd4b094/raw/8b16ff3051d3f889a3d9552d17807fb8d14a275e/HttpStatusCodes.ts}
 */
export const enum EHttpStatusCode {

	/**
	 * Standard response for successful HTTP requests.
	 * The actual response will depend on the request method used.
	 * In a GET request, the response will contain an entity corresponding to the requested resource.
	 * In a POST request, the response will contain an entity describing or containing the result of the action.
	 */
	ok = 200,

	/**
	 * The request has been accepted for processing, but the processing has not been completed.
	 * The request might or might not be eventually acted upon, and may be disallowed when processing occurs.
	 */
	accepted = 202,

	/**
	 * The server successfully processed the request and is not returning any content.
	 */
	noContent = 204,

	/**
	 * The server cannot or will not process the request due to an apparent client error
	 * (e.g., malformed request syntax, too large size, invalid request message framing, or deceptive request routing).
	 */
	badRequest = 400,

	/**
	 * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or has not yet
	 * been provided. The response must include a WWW-Authenticate header field containing a challenge applicable to the
	 * requested resource. See Basic access authentication and Digest access authentication. 401 semantically means
	 * "unauthenticated",i.e. the user does not have the necessary credentials.
	 */
	unauthorized = 401,

	/**
	 * The request was valid, but the server is refusing action.
	 * The user might not have the necessary permissions for a resource.
	 */
	forbidden = 403,

	/**
	 * The requested resource could not be found but may be available in the future.
	 * Subsequent requests by the client are permissible.
	 */
	notFound = 404,

	/**
	 * Code historique (ou très ancienne erreur avec 424) issu de eu.scenari.core.webdav.WebdavConstant.SC_METHOD_FAILURE
	 * manifestement plus spcécifiéée aujourd'hui. A renommer ?
	 */
	methodFailure = 420,

	/**
	 * A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.
	 */
	internalServerError = 500,

	/**
	 * The server either does not recognize the request method, or it lacks the ability to fulfill the request.
	 * Usually this implies future availability (e.g., a new feature of a web-service API).
	 */
	notImplemented = 501,

	/**
	 * The HTTP service is temporarily overloaded, and unable to handle the request.
	 */
	serviceUnvailable = 503,

	/**
	 * Spécifique à IEndPoint, indique une erreur réseau
	 * @see {@link IEndPoint.fetch}
	 */
	networkError = 599
}


/** Erreur (reason du rejet de la promesse) lors d'une réponse d'un fetch typé si Response.ok==false. */
export class RespError extends Error {
	constructor(message: string, readonly response: Response) {
		super(message);
	}
}

export function isRespError(e: any): e is RespError {return e?.response instanceof Response}

/** Formats disponibles d'une réponse d'un fetch. */
export type ERespFormat =
	'none'
	| 'json'
	| 'text'
	| 'dom'
	/** détection auto en fonction du mime-type : à valider l'intérêt... */
	| 'auto';

/** Response d'un fetch intégant des formats déjà résolus. */
export interface IResponse extends Response {
	asJson?: Jsonisable;
	asText?: string;
	asDom?: Document;
	/** En cas d'erreur qui a provoqué un rejet d'une promesse, la réponse est résolue avec cette erreur renseignée. */
	error?: Error;
}

/**
 * Interception des erreurs indépendamment de l'appelant pour le traitement des erreures génériques.
 * Note: pas appelée si retour 404.
 */
export interface INetErrorHook {
	onEndPointError: (res: IResponse) => void
}

/** Pour capter les erreurs génériques géré par le contexte applicatif (perte authentification...). */
export interface IEndPointErrorHookable extends IEndPoint {

	readonly errorHook?: INetErrorHook

	setErrorHook(errorHook: INetErrorHook): this
}

export function isEndPointErrorHookable(endPoint: IEndPoint): endPoint is IEndPointErrorHookable {return endPoint && ('setErrorHook' in endPoint)}

/**
 * Point d'accès avec auth par défaut (credentials='include' par défaut).
 */
export class AuthEndPoint implements IEndPointErrorHookable {
	readonly url: string;
	public readonly credentials: RequestCredentials;

	errorHook?: INetErrorHook;

	constructor(url: string | URL, credentials?: RequestCredentials, errorHook?: INetErrorHook) {
		this.credentials = credentials || 'include';
		this.errorHook = errorHook;
		if (url == null) return;
		if (url instanceof URL) {
			this.url = url.href;
		} else {
			this.url = new URL(url, document.baseURI).href; //normalisation de l'URL.
		}
	}

	/**
	 * Fetch TOUJOURS résolu, jamais rejeté.
	 * En cas d'anomalie (qui aurait provoqué un rejet de la promesse), IResponse.error est renseignée.
	 */
	fetch(path?: string, format?: ERespFormat, init?: RequestInit): Promise<IResponse> {
		return IO.resolveResp(this.doFetch(path, this.adjustRequest(init)), format, this.errorHook);
	}

	/**
	 * Fetch résolu si le statut de la réponse est ok [200-299 | 404], et que le parsing JSON n'a produit aucune exception.
	 * Retourne null si la réponse http est 204 ou 404
	 * Dans tous les autres cas la promesse est rejetée.
	 */
	fetchJson<J>(path?: string, init?: RequestInit): Promise<J | null> {
		return IO.respJson(this.doFetch(path, this.adjustRequest(init)), this.errorHook);
	}

	fetchText(path?: string, init?: RequestInit): Promise<string | null> {
		return IO.respText(this.doFetch(path, this.adjustRequest(init)), this.errorHook);
	}

	fetchDom(path?: string, init?: RequestInit): Promise<(Document & IEndPointHolder) | null> {
		if (path) return this.resolve(path).fetchDom(null, init); //pour un IEndPointHolder sur le doc juste.
		return IO.respDom(this.doFetch(path, this.adjustRequest(init)), this, this.errorHook);
	}

	fetchBlob(path?: string, init?: RequestInit): Promise<Blob | null> {
		return IO.respBlob(this.doFetch(path, this.adjustRequest(init)), this.errorHook);
	}

	fetchVoid(path?: string, init?: RequestInit): Promise<void | null> {
		return IO.respVoid(this.doFetch(path, this.adjustRequest(init)), this.errorHook);
	}

	importJs(path?: string): Promise<any> {
		const url = path ? this.resolve(path).url : this.url;

		let crossOrigin: string;
		if (this.credentials === "include") crossOrigin = "use-credentials";
		else if (this.credentials === "same-origin") crossOrigin = "anonymous";

		let corsScript: HTMLScriptElement;
		if (crossOrigin) {
			corsScript = document.createElement("script");
			corsScript.crossOrigin = crossOrigin;
			corsScript.type = "module";
			corsScript.src = url;
			document.documentElement.appendChild(corsScript);
			return (import(url) as any).finally((exports: any) => {
				corsScript.remove();
				return exports;
			});
		}
		return import(url);
	}

	protected adjustRequest(req?: RequestInit): RequestInit {
		if (!req) req = {};
		req.credentials = this.credentials;
		return req;
	}

	protected doFetch(path: string, init: RequestInit): Promise<Response> {
		//important : passer par new Url(...).href permet de bien encoder les QS en utf-8.
		//dans certaines situations (vieu profile de chrome apparemment), la QS est encodée en iso-8859-1.
		return fetch(path ? new URL(path, this.url).href : this.url, init);
	}

	resolve(url: string) {return new AuthEndPoint(this.url ? new URL(url, this.url) : url, this.credentials, this.errorHook)}


	setErrorHook(errorHook: INetErrorHook): this {
		this.errorHook = errorHook;
		return this;
	}

	toString() {return this.url}
}

/** Point d'accès public sans envoi d'authentification (credentials='omit' par défaut). */
export class PublicEndPoint extends AuthEndPoint {

	constructor(url: string | URL, credentials?: RequestCredentials, errorHook?: INetErrorHook) {
		super(url, credentials || 'omit', errorHook);
	}

	resolve(url: string) {return new PublicEndPoint(this.url ? new URL(url, this.url) : url, this.credentials, this.errorHook)}
}

/** Basic auth injectée dans la requête. */
export class BasicAuthEndPoint extends AuthEndPoint {
	protected auth: string;

	constructor(url: string | URL, user: string, pass: string, errorHook?: INetErrorHook) {
		super(url, 'include', errorHook);
		if (user) this.auth = 'Basic ' + btoa(user + ':' + pass);
	}

	adjustRequest(req?: RequestInit): RequestInit {
		if (!req) req = {};
		if (req.headers) {
			(req.headers as any).Authorization = this.auth;
		} else {
			req.headers = {Authorization: this.auth};
		}
		return req;
	}

	setAuth(auth: string): this {
		this.auth = auth;
		return this;
	}

	resolve(url: string) {return new BasicAuthEndPoint(this.url ? new URL(url, this.url) : url, null, null, this.errorHook).setAuth(this.auth)}
}

/** Point d'accès non disponible. */
export class NotAvailableEndPoint implements IEndPoint {

	static DEFAULT = new NotAvailableEndPoint();

	constructor(readonly error: number = EHttpStatusCode.notImplemented, readonly url: string = "NotAvailableEndPoint") {
	}

	fetch(path?: string, format?: ERespFormat, init?: RequestInit): Promise<IResponse> {
		return Promise.resolve(new Response(null, {status: this.error}));
	}

	fetchJson<O>(path?: string, init?: RequestInit): Promise<O | null> {
		return this.fetchVoid(path, init) as any;
	}

	fetchText(path?: string, init?: RequestInit): Promise<string | null> {
		return this.fetchVoid(path, init) as any;
	}

	fetchDom(path?: string, init?: RequestInit): Promise<(Document & IEndPointHolder) | null> {
		return this.fetchVoid(path, init) as any;
	}

	fetchBlob(path?: string, init?: RequestInit): Promise<Blob | null> {
		return this.fetchVoid(path, init) as any;
	}

	fetchVoid(path?: string, init?: RequestInit): Promise<void | null> {
		return Promise.reject(new RespError(path ? `'${path}' from ${this.url}` : this.url, new Response(null, {status: this.error})));
	}

	importJs(path?: string): Promise<any> {
		return this.fetchVoid(path) as any;
	}

	resolve(url: string) {return new NotAvailableEndPoint(this.error, url)}

}

/** IEndPoint racine permettant de rediriger sur différents autres IEndPoint. */
abstract class EndPointDispatcher implements IEndPoint {

	protected _base: IEndPoint;

	get base(): IEndPoint {return this._base || NotAvailableEndPoint.DEFAULT}

	get url(): string {return this.base.url}

	fetch(path?: string, format?: ERespFormat, init?: RequestInit): Promise<IResponse> {
		return (path ? this.resolve(path) : this.base).fetch(null, format, init);
	}

	fetchJson<O>(path?: string, init?: RequestInit): Promise<O | null> {
		return (path ? this.resolve(path) : this.base).fetchJson<O>(null, init);
	}

	fetchText(path?: string, init?: RequestInit): Promise<string | null> {
		return (path ? this.resolve(path) : this.base).fetchText(null, init);
	}

	async fetchDom(path?: string, init?: RequestInit): Promise<(Document & IEndPointHolder) | null> {
		if (path) return this.resolve(path).fetchDom(null, init);
		const dom = await this.base.fetchDom(null, init);
		if (dom) dom.baseEndPoint = this; //surcharge du baseEndPoint pour ne pas perdre les redirections.
		return dom;
	}

	fetchBlob(path?: string, init?: RequestInit): Promise<Blob | null> {
		return (path ? this.resolve(path) : this.base).fetchBlob(null, init);
	}

	fetchVoid(path?: string, init?: RequestInit): Promise<void | null> {
		return (path ? this.resolve(path) : this.base).fetchVoid(null, init);
	}

	importJs(path: string): Promise<any> {
		return (path ? this.resolve(path) : this.base).importJs(null);
	}

	abstract resolve(path: string): IEndPoint;

}

/**
 * Resolver unique pour différents endPoints en préfixant les url d'une clé
 * encadré de ':'. Exemple : ":skin:myFolder/img.png"
 */
export class EndPointResolver extends EndPointDispatcher implements IPathResolver {

	protected _list = [] as ({ prefix: string, redirect: IEndPoint })[];

	protected _replace: RegExp;

	/** Ajoute une redirection. Le préfixe est la clé entre les ':'. */
	addEndPoint(prefix: string, redirect: IEndPoint): this {
		if (Object.isFrozen(this._list)) this._list = this._list.slice();
		this._list.push({prefix, redirect});
		delete this._replace;
		return this;
	}

	/** Une fois la config de recirections achevée, le freeze de la config optimise les résolutions. */
	freeze(): this {
		Object.freeze(this._list);
		return this;
	}

	setBase(base: IEndPoint): this {
		this._base = base;
		return this;
	}

	clone(): EndPointResolver {return new EndPointResolver().initFrom(this)}

	initFrom(other: EndPointResolver, newBase?: IEndPoint): this {
		this._list = Object.isFrozen(other._list) ? other._list : other._list.slice();
		this._base = newBase || other._base;
		return this;
	}

	resolve(path: string): IEndPoint {
		if (!path) return new EndPointResolver().initFrom(this);
		if (path.charCodeAt(0) === 58) {
			for (const entry of this._list) {
				const len = entry.prefix.length;
				if (path.charCodeAt(len + 1) === 58 && path.substr(1, len) === entry.prefix) {
					return new EndPointResolver().initFrom(this, entry.redirect.resolve(path.substring(len + 2)));
				}
			}
		}
		return new EndPointResolver().initFrom(this, this._base ? this._base.resolve(path) : new NotAvailableEndPoint(EHttpStatusCode.badRequest, path));
	}

	resolvePath(path: string): string {
		if (path.charCodeAt(0) === 58) {
			for (const entry of this._list) {
				const len = entry.prefix.length;
				if (path.charCodeAt(len + 1) === 58 && path.substr(1, len) === entry.prefix) return entry.redirect.resolve(path.substring(len + 2)).url;
			}
		}
		return path;
	}

	resolveInText(text: string): string {
		if (!this._replace) {
			const re = ["(:("];
			for (let i = 0; i < this._list.length; i++) {
				re.push(this._list[i].prefix, '|');
			}
			re[re.length - 1] = "):)";
			this._replace = new RegExp(re.join(''), "g");
		}
		return text.replace(this._replace, (m, g1, g2) => {
			for (const entry of this._list) {
				const len = entry.prefix.length;
				if (g2 === entry.prefix) return entry.redirect.url;
			}
			return m; //en principe impossible
		});
	}
}

export interface IResolverPointer {
	resolver?: EndPointResolver
}

/** Structures de données acceptables en body d'un fetch. */
export type IBody = Blob | BufferSource | FormData | string | URLSearchParams;

/** Elimine l'encodage de l'espace en '+' de l'impl standard URLSearchParams. */
export class UrlQs extends URLSearchParams {

	toString() {
		return super.toString().replace(/\+/g, "%20");
	}
}


export namespace IO {

	export const REQUEST_LANG_HEADER = "X-Lang";

	export function asEndPoint(url: string): IEndPoint {
		return new PublicEndPoint(new URL(url, document.baseURI));
	}

	/** Résoud une url relative par rapport à from ou à défaut à la baseURI du document. */
	export function resolveUrl(path: string | null, from: string | null): string {
		if (!path) return IO.absoluteUrl(from);
		return new URL(path, IO.absoluteUrl(from)).href;
	}

	/** Crée une url absolue par rapport à la baseURI du document courant. */
	export function absoluteUrl(path: string | null): string {
		if (!path) return document.baseURI;
		return new URL(path, document.baseURI).href;
	}

	export function importAllJs(urls: IEndPoint[]): Promise<any[]> {
		return Promise.all(urls.map((ep: IEndPoint) => ep.importJs()));
	}

	/** Ouvre une url dans un nouvel onglet
	 * 		@return true si l'ouverture a bien eu lieu, ou IError en cas de problème
	 */
	export async function openUrlExternal(url: string): Promise<IError | true> {
		if (!url) return;
		if (Desk.electron) {
			const reply = await IO.sendMessage({type: "client:modules:external:openUrl", url: url});
			if (reply.type == "error")
				return {msg: reply.msg} as IError;
		} else
			window.open(url);
		return true;
	}

	/**
	 * Construction d'une QueryString commencant par un '?'.
	 * Les entrées sont des couples de key/value.
	 * Si value == null le couple key/value n'est pas publié.
	 */
	export function qs(...keyValue: any[]): string {
		const qs = new UrlQs();
		for (let i = 0; i < keyValue.length; i = i + 2) {
			const value = keyValue[i + 1];
			if (value != null) qs.append(keyValue[i], value);
		}
		return "?" + qs.toString();
	}


	/**
	 * Construction d'une QueryString commencant par un '?' ou '&' ou rien.
	 * Les entrées sont un couple de key/value.
	 * Si value === null la key est publiée sans value : ?key1&key2
	 * Si value === undefined le couple key/value n'est pas publié.
	 *
	 * @param startWithQuery le 1er param est préfixé de '?' si true, de '&' si false, de rien si null.
	 */
	export function query(startWithQuery: boolean | null, ...keyValue: any[]): string {
		const qs: string[] = [];
		for (let i = 0; i < keyValue.length; i = i + 2) {
			const value = keyValue[i + 1];
			if (value === undefined) continue;
			if (startWithQuery === false) {
				qs.push('&');
			} else {
				if (startWithQuery === true) qs.push('?');
				startWithQuery = false;
			}
			if (value !== null) {
				qs.push(keyValue[i], '=', encodeURIComponent(value));
			} else {
				qs.push(keyValue[i]);
			}
		}
		return qs.join('');
	}

	/** Construction d'un FormData pour envoyer en body d'un méthode POST. */
	export function fd(...keyValue: any[]): FormData {
		const fd = new FormData();
		for (let i = 0; i < keyValue.length; i = i + 2) {
			const value = keyValue[i + 1];
			if (value != null) fd.append(keyValue[i], value);
		}
		return fd;
	}

	/** Construit un nom de fichier valide à partir d'un libellé quelconque. */
	export function getValidFileName(proposalName: string, suffix?: string, addDate?: 'date' | 'dateTime'): string {
		let validFileName = proposalName.trim().replace(/[+$\\\/><|?:*#"~]+/g, "_").substring(0, 48);
		if (addDate) {
			function s(num: number) {
				const s = num.toString();
				return s.length === 1 ? "0" + s : s;
			}

			const d = new Date();
			if (addDate === 'date') {
				validFileName = `${validFileName}_${d.getFullYear()}-${s(d.getMonth() + 1)}-${s(d.getDate())}`;
			} else {
				validFileName = `${validFileName}_${d.getFullYear()}-${s(d.getMonth() + 1)}-${s(d.getDate())}_${s(d.getHours())}-${s(d.getMinutes())}-${s(d.getSeconds())}`;
			}
		}
		if (suffix) validFileName = validFileName + suffix;
		return validFileName;
	}

	/** Enregistrement d'une réponse d'une requete dans fichier en local
	 * avec prompt de la fenetre de choix du file de destination
	 * 	FIXME : surveiller l'adoption de https://wicg.github.io/file-system-access/#api-showopenfilepicker & co par les browsers, pour écrire celà plus proprement
	 * 	Besoins :
	 * 		- la méthode POST doit fonctionner ;
	 * 		- exploitation de la progression du download native du browser ;
	 * 	Limites de cette approche :
	 * 		- nécessite un contexte UI
	 * 		- si failed, alors la page courante est remplacée par le retour de la requête...
	 */
	export function saveRespAs(urlEp: IEndPoint, ctxElt: HTMLElement, params?: Map<string, string>, acceptCharset?: string, method: 'GET' | 'POST' | 'PUT' = 'GET'): void {
		const form = ctxElt.appendChild(ctxElt.ownerDocument.createElement('form')) as HTMLFormElement;
		try {
			form.hidden = true;
			form.method = method;
			form.action = urlEp.url;
			//moins fluide coté UI, mais évite une perte UI complète en cas de failed...
			//form.target = "_blank";
			if (acceptCharset) form.acceptCharset = acceptCharset;
			params?.forEach((value, key) => {
				const input = form.appendChild(ctxElt.ownerDocument.createElement('input')) as HTMLInputElement;
				input.type = "hidden";
				input.name = key;
				input.value = value;
			})
			form.submit();
		} finally {
			form?.remove();
		}
	}

	/**
	 * Sécurité Xss pour une url de redirection passée en QS.
	 * @see https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html#validating-urls
	 *
	 * On limite aux pages (x)html, ce qui bloque toute interrogation directe d'un svc
	 *
	 * @param checkHostName
	 * 		si true, le hostName de r doit être égal à document.location.hostname,
	 * 		si tableau de string, vérifie que le hostName de r est dans cette liste blanche.
	 */
	export function isRedirectValid(r: string, checkHostName?: boolean | string[]) {
		if (!r) return false;
		if (r.charAt(0) === "/" && r.charAt(1) !== "/") return true; // path absolu dans le domaine => ok (mais pas "//..." qui redéfinit le domaine)
		if (/^http(s)?:\/\//.test(r) || r.startsWith("//")) {
			//on limite aux protocole http(s), (exclus javascript: data: ...)
			const target = new URL(r, document.baseURI);
			//on limite aux pages (x)html (bloque toute interrogation directe d'un svc).
			//if (!/.*\.(xhtml|html)$/.test(target.pathname)) return false;
			const hostName = target.hostname;
			if (checkHostName === true) {
				if (document.location.hostname !== hostName) return false;
			} else if (Array.isArray(checkHostName)) {
				if (document.location.hostname !== hostName && checkHostName.indexOf(hostName) < 0) return false;
			}
			return true;
		}
		return false;
	}

	export function indexOf(url: IEndPoint, inArray: IEndPoint[]): number {
		return inArray.findIndex((u: IEndPoint) => {return u.url === url.url})
	}

	export function appendUrl(toArray: IEndPoint[], ...url: IEndPoint[]): void {
		for (const u of url) if (indexOf(u, toArray) < 0) toArray.push(u);
	}

	export function addLang(init?: RequestInit, lang?: string): RequestInit {
		const i = init || {};
		if (!i.headers) i.headers = {};
		(i.headers as any)[REQUEST_LANG_HEADER] = lang || REG.reg.getPref("lang");
		return i;
	}

	/**
	 * Gère une promesse de Response d'un fetch en rejetant la promesse si le state de la réponse
	 * n'est pas dans la plage [200-299 | 404] et retourne un json du body de la réponse typé en <R> ou
	 * null si la réponse est 204 ou 404.
	 */
	export async function respJson<R>(promise: Promise<Response>, errorHook?: INetErrorHook): Promise<R | null> {
		let resp: Response;
		try {
			resp = await promise;
		} catch (e) {
			if (errorHook) errorHook.onEndPointError(e);
			throw e;
		}
		if (!resp.ok) {
			if (resp.status === 404) return null;
			if (errorHook) errorHook.onEndPointError(resp);
			throw new RespError("Status response: " + resp.status, resp);
		}
		return resp.status === 204 ? null : resp.json();
	}

	export async function respText(promise: Promise<Response>, errorHook?: INetErrorHook): Promise<string | null> {
		let resp: Response;
		try {
			resp = await promise;
		} catch (e) {
			if (errorHook) errorHook.onEndPointError(e);
			throw e;
		}
		if (!resp.ok) {
			if (resp.status === 404) return null;
			if (errorHook) errorHook.onEndPointError(resp);
			throw new RespError("Status response: " + resp.status, resp);
		}
		return resp.status === 204 ? null : resp.text();
	}

	export async function respDom(promise: Promise<Response>, endPoint: IEndPoint, errorHook?: INetErrorHook): Promise<(Document & IEndPointHolder) | null> {
		let resp: Response;
		try {
			resp = await promise;
		} catch (e) {
			if (errorHook) errorHook.onEndPointError(e);
			throw e;
		}
		if (!resp.ok) {
			if (resp.status === 404) return null;
			if (errorHook) errorHook.onEndPointError(resp);
			throw new RespError("Status response: " + resp.status, resp);
		}
		return resp.status === 204 ? null : DOM.parseDom(await resp.text(), endPoint);
	}

	export async function respBlob(promise: Promise<Response>, errorHook?: INetErrorHook): Promise<Blob | null> {
		let resp: Response;
		try {
			resp = await promise;
		} catch (e) {
			if (errorHook) errorHook.onEndPointError(e);
			throw e;
		}
		if (!resp.ok) {
			if (resp.status === 404) return null;
			if (errorHook) errorHook.onEndPointError(resp);
			throw new RespError("Status response: " + resp.status, resp);
		}
		return resp.blob();
	}

	export async function respVoid(promise: Promise<Response>, errorHook?: INetErrorHook): Promise<void | null> {
		let resp: Response;
		try {
			resp = await promise;
		} catch (e) {
			if (errorHook) errorHook.onEndPointError(e);
			throw e;
		}
		if (!resp.ok) {
			if (resp.status === 404) return null;
			if (errorHook) errorHook.onEndPointError(resp);
			throw new RespError("Status response: " + resp.status, resp);
		}
	}

	/**
	 * Gère une promesse de Response d'un fetch afin que la promesse ne soit JAMAIS rejetée.
	 * Enrichit la réponse d'un fetch natif du format demandé ou l'évalue dynamiquement en fonction du Content-type de la response.
	 * En cas d'erreur réseau, un IResponse avec IResponse.status = 599 et IResponse.error renseigné est retourné.
	 * En cas d'erreur de parsing, IResponse.error est renseigné avec l'erreur issue du parsing.
	 */
	export async function resolveResp(promise: Promise<Response>, format: ERespFormat = 'none', errorHook?: INetErrorHook): Promise<IResponse> {
		let resp: IResponse;
		try {
			resp = await promise;
			if (errorHook && !resp.ok && resp.status !== 404) errorHook.onEndPointError(resp);
			if (resp.status === EHttpStatusCode.noContent) return resp; //aucun contenu
			if (format === 'auto') {
				//Eval dynamique du format demandé
				const ct = resp.headers.get('Content-type') || "";
				if (ct.indexOf("json") >= 0) {
					format = 'json';
				} else if (ct.indexOf("xml") >= 0) {
					format = 'dom';
				} else if (ct.indexOf("text") >= 0) {
					format = 'text';
				}
			} else if (!resp.ok) {
				//réponse en erreur on vérifie avec le content-length et le content-type retourné que le format demandé est disponible.
				const ct = resp.headers.get('Content-type');
				const len = resp.headers.get('Content-length');
				if (ct && len && parseInt(len, 10) > 0) {
					if (format === 'json' && ct.indexOf("json") < 0) return resp;
					else if (format === 'dom' && ct.indexOf("xml") < 0) return resp;
					else if (format === 'text' && ct.indexOf("text") < 0) return resp;
				} else return resp;
			}
			switch (format) {
			case 'json':
				try {
					resp.asJson = await resp.json();
				} catch (e) {
					resp.asText = await resp.text();
					throw e;
				}
				break;
			case 'text':
				resp.asText = await resp.text();
				break;
			case 'dom':
				resp.asText = await resp.text();
				resp.asDom = new DOMParser().parseFromString(resp.asText, "text/xml");
			}
			return resp;
		} catch (e) {
			if (!resp) resp = new Response(null, {status: EHttpStatusCode.networkError}) as IResponse;
			resp.error = e;
			if (errorHook) errorHook.onEndPointError(resp);
			return resp;
		}
	}

	/**
	 * Fonction utilitaire construisant un rapport à partir d'un code HTTP.
	 *
	 * Utilisé par les différentes implémentations du contrôle d'URL (electron, serveur, fetch)
	 *
	 * @param status Code de status HTTP de la réponse
	 */
	export function reportFromStatus(status: number): IUrlCheckReport {
		switch (status) {
		case 200:
			return {status: "ok", desc: "Valid URL."};
		case 401:
			return {status: "auth", desc: "This URL requires an authentication to be checked (error 401)."};
		case 403:
			return {status: "auth", desc: "Access to this URL is not authorized (error 403)."};
		case 404:
			return {status: "error", desc: "Invalid URL (error 404)."};
		case 405:
		case 501:
			return {status: "net", desc: `Cannot check this URL automatically (${status} error).`};
		default:
			switch (status.toString().charAt(0)) {
			case "2":
				return {status: "ok", desc: `This URL is valid (${status} code).`};
			case "3":
				return {status: "error", desc: `This URL is redirected (${status} code).`};
			case "4":
				return {status: "error", desc: `This URL is not correct (${status} client error).`};
			case "5":
				return {status: "error", desc: `This URL is not correct (${status} server error).`};
			default:
				return {status: "error", desc: `This URL is not correct (${status} error).`};
			}
		}
	}

	/**
	 * Variante de postMessage attendant une réponse par l'API "Channel Messaging".
	 *
	 * Le récepteur du message est censé exister et répondre. Dans le cas contraire, l'envoi sera rejeté par timeout.
	 *
	 * @param message Message a envoyé
	 * @param targetOrigin Cible du message, location.origin par défaut
	 * @param timeout Délai avant lequel l'envoi du message est considéré comme rejeté, cinq secondes par défaut
	 * // TODO Type checking des messages de l'envoi et de la réponse ?
	 */
	export function sendMessage(message: any, targetOrigin = location.origin, timeout = 5000): Promise<any> {
		const {port1, port2} = new MessageChannel();
		return new Promise<any>((resolve, reject) => {
			window.parent.postMessage(message, location.origin, [port2]);

			const rejectTimeout = timeout && setTimeout(() => {
				port1.close();
				port2.close();
				reject(new Error("Timeout while waiting for a reply"));
			}, timeout);

			port1.onmessage = (ev) => {
				if (timeout) clearTimeout(rejectTimeout);
				port1.close();
				port2.close();
				resolve(ev.data);
			}
			port1.onmessageerror = () => {
				if (timeout) clearTimeout(rejectTimeout);
				port1.close();
				port2.close();
				reject(new Error("Unable to deserialize the reply"));
			}
		});
	}
}

/** Requête pour la vérification d'une URL */
export interface IUrlCheckRequest extends Jsonisable {
	/** URL à tester */
	url: string;
	/** Headers à extraire et à retourner dans le rapport au moment de la vérification */
	extractHeaders?: string[];
	/** Méthodes à utiliser à tester, permet de gérer un fallback GET si le HEAD échoue */
	methods?: string[];
	/** Timeout en milisecondes à utiliser pour le traitement de la requête */
	timeout?: number;
}

/** Rapport retourné par la vérification d'URL */
export interface IUrlCheckReport {
	/** Statut de la vérification */
	status: 'ok' | 'net' | 'auth' | 'error' | 'invalid';
	/** Description localisé de la vérification */
	desc: string;
	/** Headers extraient lors de la vérification */
	headers?: Dict<string[]>;
}
